import React, { useState, useContext, useEffect, useRef } from 'react'
import { RichText } from 'prismic-reactjs'
import SEO from '../components/SEO';
import Header from '../components/Header';
import Button from '../components/Button';
import Footer from '../components/Footer';
import ColorsGlazesShapes from '../components/ColorsGlazesShapes';
import DualImageHover from '../components/DualImageHover';
import DesignCustomization from '../components/DesignCustomization'
import { detectElementsOnScreen, killObservers } from '../helpers/detectElementsOnScreen'
import { ContextLoader } from '../context';
import ImageSlider from '../components/ImageSlider';
import ImageSliderSwiperjs from '../components/ImageSliderSwiperjs';
import Picture from '../components/Picture';
import SplitText from '../components/SplitText';
import ParallaxImage from '../components/ParallaxImage';
import getBase64 from '../helpers/getBase64';
import emailjs from 'emailjs-com';

const FORM_TEMPLATE_ID = 'contact_form'
const CONTACT_SERVICE_ID = process.env.GATSBY_EMAILJS_SERVICE_ID
const CONTACT_USER_ID = process.env.GATSBY_EMAILJS_USER_ID

const LandingPage = ({ data }) => {

	// to show .onScreen animations for button/ footer=images etc..otherwise they will be hidden due to opacity 0
	const [isLoading] = useContext(ContextLoader);
	useEffect(() => {
		if (isLoading === true) return
		const [observers, elements] = detectElementsOnScreen()
		return () => {
			killObservers(observers, elements)
		}
	}, [isLoading])

	const userInfosDefault = {
		firstname: '',
		lastname: '',
		email: '',
		phone: '',
		street_address: '',
		city: '',
		state_province: '',
		country: '',
		street_address_2: '',
		preferred_way_of_contact: '',
		// resume: '',
		comments: ''
	}
	const [userInfos, setUserInfos] = useState(userInfosDefault)
	const [showSuccessMessage, setShowSuccessMessage] = useState(null)
	const [showErrorMessage, setShowErrorMessage] = useState(null)
	const [isSubmissionLoading, setIsSubmissionLoading] = useState(false)
	const formEl = useRef(null)
	const inputUploadEl = useRef(null)

	const handleInputChange = (property, value) => {
		const userInfosClone = {...userInfos}
		userInfosClone[property] = value
		setUserInfos(userInfosClone)
	}

	const handleInputUploadChange = () => {
		const userInfosClone = {...userInfos}
		const inputUploadFile = inputUploadEl.current.files[0];
		if (inputUploadFile === undefined) return;
		getBase64(inputUploadFile).then(data => {
			userInfosClone.resume = data
			setUserInfos(userInfosClone)
		})
	}

	const onButtonClick = e => {
		const isFormValid = formEl.current.reportValidity()

		if (!isFormValid) return

		setShowSuccessMessage(false)
		setShowErrorMessage(false)
		setIsSubmissionLoading(true)

		emailjs.send(CONTACT_SERVICE_ID, FORM_TEMPLATE_ID, userInfos, CONTACT_USER_ID)
			.then(
				res => {
					setUserInfos(userInfosDefault)
					setIsSubmissionLoading(false)
					setShowSuccessMessage(true)
				},
				err => {
					setIsSubmissionLoading(false)
					setShowErrorMessage(true)
				}
			)
	}

	const prismicNavigation = data.prismicNavigation
	const prismicFooter = data.prismicFooter
	const pageData = data.prismicLandingPage.data;
	// const pageDataRaw = data.prismicLandingPage.dataRaw;



	const pageTitle = "Terracotta Rainscreen Explained | Shildan Group";
	const metaDescription = "Our terracotta rainscreen system is a high performing, back ventilated, pressure equalized, open jointed rainscreen system, designed using the Rainscreen Principle.";
	const metaKeywords = "Teracotta rainscreens, teracotta sunscreens, terracotta curtain wall";

    return (
		<div>
			<SEO title={pageTitle} description={metaDescription} keywords={metaKeywords} />
			<Header currentPage="landing" navigation={prismicNavigation} footer={prismicFooter} />

			<div className="OurProducts ProductCategories" style={{paddingTop: 200}}>
				<div className="ProductCategories__Item">

					<div className="ProductCategories__TitleSection">
						<h1 className="ProductCategories__Title" style={{paddingBottom: 20, fontSize: '1.4em'}}>
							{pageData.title[0]?.text}
						</h1>
						<div className="ProductCategories__TitleDescription">
							{pageData.top_paragraph.map(p => {
								return <p style={{paddingBottom: 30}}>{p.text}</p>
							})}
						</div>
					</div>

					<div className="ProductCategories__Section ProductCategories__SideBySideImage" style={{marginTop: 20, marginBottom: 75}}>
						<div className="ProductCategories__SideBySideImageContainer">
							<div className="sliderColorsShapes__MobileSWiper showOnDesktop">
								<div className="ProductCategories__Section ProductCategories__FullWidthImage" style={{height: 'unset'}}>
									<Picture
										src={pageData.top_image_left.url}
										alt={pageData.top_image_left.alt}
										sizes="94.44vw"
									/>
								</div>
							</div>
						</div>
						<div className="ProductCategories__SideBySideImageContainer">
							<div className="sliderColorsShapes__MobileSWiper showOnDesktop">
								<div className="ProductCategories__Section ProductCategories__FullWidthImage" style={{height: 'unset'}}>
									<Picture
										src={pageData.top_image_right.url}
										alt={pageData.top_image_right.alt}
										sizes="94.44vw"
									/>
								</div>
							</div>
						</div>
					</div>

					<div className='smallTopBorder'></div>

					<div className="ProductCategories__TitleSection">
						<div className="ProductCategories__Title" style={{marginBottom: 30}}>
							{pageData.subheading_1[0]?.text}
						</div>
						<div className="ProductCategories__TitleDescription">
							{pageData.paragraph_2.map(p => {
								if (p.type === 'paragraph')
									return <p style={{paddingBottom: 20}}>{p.text}</p>
								if (p.type === "list-item")
									return <li style={{paddingLeft: 10}}>{p.text}</li>
							})}
						</div>
					</div>


					<div className="ProductCategories__SubHeadingSection">
						<div className="ProductCategories__SubHeading" style={{flex: '1 0 35%'}}>
							{pageData.subheading_2[0]?.text}
						</div>
						<div className="ProductCategories__SubHeadingDescription">
							<img
								src={pageData.body_image.url}
								// srcSet={pageData.body_image.fluid.srcSet}
								// alt={pageData.body_image.fluid.alt}
								sizes="94.44vw"
								style={{width: '100%'}}
							/>
						</div>
					</div>

					<div className='smallTopBorder'></div>

					<div className="ProductCategories__TitleSection">
						<div className="ProductCategories__Title">
							{pageData.subheading_3[0]?.text}
						</div>
						<div className="ProductCategories__TitleDescription" style={{paddingBottom: 70}}>
							{pageData.paragraph_3.map(p => {
								return <p style={{paddingBottom: 20}}>{p.text}</p>
							})}
						</div>
					</div>

					<div className='smallTopBorder'></div>

					<div className="ProductCategories__TitleSection Advantages-section">
						<div className="ProductCategories__Title advantages-title">
							{pageData.subheading_4[0]?.text}
						</div>
						<div className="ProductCategories__TitleDescription">
							{pageData.paragraph_4.map(p => {
								if (p.type === 'paragraph')
									return <p style={{paddingBottom: 20}}>{p.text}</p>
							})}
							<ul style={{listStyle: 'unset'}}>
							{pageData.paragraph_4.map(p => {
								if (p.type === "list-item")
									return <li>{p.text}</li>
							})}
							</ul>
						</div>
					</div>

					<div className='smallTopBorder'></div>

					<div className="ProductCategories__TitleSection">
						<div className="ProductCategories__Title">
							{pageData.subheading_5[0]?.text}
						</div>
						<div className="ProductCategories__TitleDescription">
							{pageData.paragraph_5.map(p => {
								return <p>{p.text}</p>
							})}
						</div>
					</div>

					<section className="GetInTouch__Landing">
					<h3
						className="GetInTouch__CareersTitle"
						style={{fontSize: '3.5em', paddingTop: '2vw'}}
						data-is-on-screen
					>
						<span>
							Get In Touch
						</span>
					</h3>
					<div
						className="GetInTouch__CareersDescription"
						style={{marginTop: 0}}
						data-is-on-screen
					>
						<form
						className="GetInTouch__CareersForm"
						style={{marginLeft: 0}}
						ref={formEl}
					>
						<div className="GetInTouch__CareersFormContainer">
							<div className="GetInTouch__CareersInputContainer">
								<input
									className="GetInTouch__CareersInput"
									placeholder="First Name*"
									type="text"
									required
									value={userInfos.firstname}
									onChange={e => handleInputChange('firstname', e.target.value)}
								/>
							</div>
							<div className="GetInTouch__CareersInputContainer">
								<input
									className="GetInTouch__CareersInput"
									placeholder="Last Name*"
									type="text"
									required
									value={userInfos.lastname}
									onChange={e => handleInputChange('lastname', e.target.value)}
								/>
							</div>
							<div className="GetInTouch__CareersInputContainer">
								<input
									className="GetInTouch__CareersInput"
									placeholder="Email Address*"
									type="email"
									required
									value={userInfos.email}
									onChange={e => handleInputChange('email', e.target.value)}
								/>
							</div>
							<div className="GetInTouch__CareersInputContainer">
								<input
									className="GetInTouch__CareersInput"
									placeholder="Phone Number*"
									type="tel"
									required
									value={userInfos.phone}
									onChange={e => handleInputChange('phone', e.target.value)}
								/>
							</div>
							<div className="GetInTouch__CareersInputContainer">
								<input
									className="GetInTouch__CareersInput"
									placeholder="Street Address*"
									type="text"
									required
									value={userInfos.street_address}
									onChange={e => handleInputChange('street_address', e.target.value)}
								/>
							</div>
							<div className="GetInTouch__CareersInputContainer">
								<input
									className="GetInTouch__CareersInput"
									placeholder="City*"
									type="text"
									required
									value={userInfos.city}
									onChange={e => handleInputChange('city', e.target.value)}
								/>
							</div>
							<div className="GetInTouch__CareersInputContainer">
								<input
									className="GetInTouch__CareersInput"
									placeholder="State / Province*"
									type="text"
									required
									value={userInfos.state_province}
									onChange={e => handleInputChange('state_province', e.target.value)}
								/>
							</div>
							<div className="GetInTouch__CareersInputContainer">
								<input
									className="GetInTouch__CareersInput"
									placeholder="Country*"
									type="text"
									required
									value={userInfos.country}
									onChange={e => handleInputChange('country', e.target.value)}
								/>
							</div>
							<div className="GetInTouch__CareersInputContainer">
								<input
									className="GetInTouch__CareersInput"
									placeholder="Street Address 2"
									type="text"
									value={userInfos.street_address_2}
									onChange={e => handleInputChange('street_address_2', e.target.value)}
								/>
							</div>
							<div className="GetInTouch__CareersInputContainer">
								<input
									className="GetInTouch__CareersInput"
									placeholder="Where should we contact you?"
									type="text"
									value={userInfos.preferred_way_of_contact}
									onChange={e => handleInputChange('preferred_way_of_contact', e.target.value)}
								/>
							</div>
						</div>
						<div className="GetInTouch__CareersInputTextareaContainer">
							<textarea
								className="GetInTouch__CareersInputTextarea"
								placeholder="Additional Comments"
								rows="6"
								value={userInfos.comments}
								onChange={e => handleInputChange('comments', e.target.value)}
							/>
						</div>
						<Button
							className="GetInTouch__CareersButton"
							modifier="big"
							onClick={onButtonClick}
						>
							Submit
						</Button>
						<div className="GetInTouch__SubmitMessages">
							{isSubmissionLoading &&
								<div className="GetInTouch__SubmitMessage GetInTouch__SubmitMessage--Loader">
									We are processing your submission, it should take a few seconds... Please do not close or refresh this tab.
								</div>
							}
							{showSuccessMessage &&
								<div className="GetInTouch__SubmitMessage GetInTouch__SubmitMessage--Success">
									Thank you! Your submission has been sent to our team. We will get in touch with you very soon.
								</div>
							}
							{showErrorMessage &&
								<div className="GetInTouch__SubmitMessage GetInTouch__SubmitMessage--Error">
									It look like there was a problem on our end. Please come back later on contact us directly at info@shildan.com.
								</div>
							}
						</div>
					</form>
					</div>
					

				</section>
					
				</div>
			</div>
			
			<Footer footer={prismicFooter.data} showImage={false} />
		</div>
	)
}

export const query = graphql`
	query LandingPage {
		prismicLandingPage {
			data {
				subheading_1 {
                    type
                    text
                  }
                  title {
                    type
                    text
                  }
                  top_paragraph {
                    type
                    text
                  }
                  body_image {
                    url
                  }
                  paragraph_2 {
                    type
                    text
                  }
                  paragraph_3 {
                    type
                    text
                  }
                  paragraph_4 {
                    type
                    text
                  }
                  paragraph_5 {
                    type
                    text
                  }
                  subheading_2 {
                    type
                    text
                  }
                  subheading_3 {
                    type
                    text
                  }
                  subheading_4 {
                    type
                    text
                  }
                  subheading_5 {
                    type
                    text
                  }
                  top_image_left {
                    url
                  }
                  top_image_right {
                    url
                  }
			}
		}
		prismicNavigation {
        ...HeaderQuery
        }
		prismicFooter {
			...FooterQuery
		}
	}
`

export default LandingPage;